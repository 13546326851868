<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>市场数据分析</a-breadcrumb-item>
            <a-breadcrumb-item>库存进出统计</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                </div>
                <div class="search">
                    <a-form layout='inline'>
                    <a-form-item>
                        <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
                        <a-radio-button value="day">
                            日
                        </a-radio-button>
                        <a-radio-button value="week">
                            周
                        </a-radio-button>
                        <a-radio-button value="month">
                            月
                        </a-radio-button>
                        <a-radio-button value="year">
                            年
                        </a-radio-button>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>时间筛选</span>
                        </template>
                        <a-range-picker
                            :ranges="rangesData"
                            :value='rangesValue'
                            :disabled-date="disabledDate" :allowClear="false"
                            :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                            @change="(date, dateString)=>onChange(date, dateString,1)" />
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-button @click="toScreen" :loading="loading" type="primary">筛选</a-button>
                    </a-form-item>
                    </a-form>
                </div>
            </div>
            <div class="dashboard bk-group" >
                <statistics :count='counts' col="6" />
                <a-row class="mb16" :gutter="16">
                    <a-col :lg="24" :xl="12">
                        <income :loading='loading' :item ='incomeData' :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <outcome :loading='loadingO' :item ='outcomeData' :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
                    </a-col>
                </a-row>
            </div>
        </div>
    </div>
</template>

<script>
    import income from './income'
    import outcome from './outcome'
    import moment from 'moment'
    import ranges from "@/common/mixins/ranges"
    import statistics from '@/views/bi/ranking/money/statistics'
    export default {
        name:'kctj',
        components:{
            income,
            outcome,
            statistics
        },
        data() {
            return {
                loading: false,
                loadingO:false,
                incomeData:{},
                outcomeData:{},
                counts:[],
                paramsData:{
                    start_date:moment().startOf('month').format('YYYY-MM-DD'),
                    end_date:moment().format('YYYY-MM-DD'),
                    filter_type:'day'
                },
            }
        },
        mixins: [ranges],
        created(){
            this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
            this.toScreen()
        },
        methods:{
            moment,
            getList(obj){
                this.getCount(obj)
                this.getIncome(obj)
                this.getOutcome(obj)
            },
            async getIncome(obj){
                this.loading = true
                let res = await this.$store.dispatch('analysisStockIncomeAction', obj)
                this.incomeData = res.data
                this.loading = false
            },
            async getOutcome(obj){
                this.loadingO = true
                let res = await this.$store.dispatch('analysisStockOutcomeAction', obj)
                this.outcomeData = res.data
                this.loadingO = false
            },
            async getCount(obj){
              let res = await this.$store.dispatch('analysisStockCountAction', obj)
              this.counts = res.data
            },
            handleButChange(e){
                this.paramsData.filter_type = e.target.value
                this.toScreen();
            },
            toScreen(){
                let obj = {
                    search:this.paramsData
                }
                this.getList(obj);
            }
        }
    }
</script>